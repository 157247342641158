import React, { useState } from 'react'
import styled from 'styled-components'
import UserProfile from "../UserProfile/UserProfile";
import history from '../history'
import axios from 'axios';


export const ValidationButton = ({Title, ButtonSize, border, onClick, goTo= "default", cart, color, save}) => {
    const checkRedirect = () => {
        if (goTo === "connexion") {
            if (UserProfile.getName()) {
                history.push("/panier");
                return
            } else {
                //UserProfile.setCart(JSON.stringify(cart));
                const config = {
                    method: 'POST',
                    url:  `https://api.flexibles.xyz/shopping-cart`,
                    headers: { 
                      'Content-Type': 'application/json',
                    },
                    data : cart
                  };
                  axios(config)
                    .then(function (responsePut) {
                        UserProfile.setCartId(responsePut.data._id);
                        history.push("/connexion");
                    }).catch(function (errorPut) {
                        console.log(errorPut)
                    })
                return
            }
        }
        if (goTo === "register") {
            onClick(true);
            return;
        }
        if (goTo === "regi") {
            onClick();
            return;
        }
        if (goTo === "login") {
            onClick(true);
            return;
        }
        if (goTo === "payment") {
            onClick();
            return;
        }
        onClick();
    };
    return (
        <Validation ButtonSize={ButtonSize} border={border} onClick={() => {checkRedirect();}} color={color}>
            {Title}    
        </Validation>
    )
}

const Validation = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.color ? props.color : "white"};
    width: ${props => props.ButtonSize.width};
    height: ${props => props.ButtonSize.height};
    color: ${props => props.color ? "white" : "#16B7AB"} ;
    border: 1px solid ${props => props.border ? props.border : "transparent"};
    border-radius: 15px;
    box-shadow: 3px 3px 10px #00000029;;
    font: normal normal 600 17px/26px Poppins;
    @media screen and (max-device-width:480px){
        font-size: 14px
    }
    :hover {
        box-shadow: 3px 3px 10px grey;
        cursor: pointer;
    }
    
`