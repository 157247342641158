import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import UserProfile from '../UserProfile/UserProfile'
import { LoginForm } from '../LoginForm'

export const TopBar = ({user, setChange, change}) => {
    const [inscriptionOpen, setInscriptionOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [displayProfile, setDisplayProfile] = useState(false)
    const deconnect = () => {
        localStorage.removeItem('user');
        setDisplayProfile(false)
        setChange(!change)
        var win = window.open(process.env.REACT_APP_MES_SEJOUR_URL, '_self');
        win.focus();
    }

    
    useEffect(() => {
        if (!user) {
            if (loginOpen || inscriptionOpen) {
                document.body.style.overflow = "hidden"
            }
            if (!loginOpen && !inscriptionOpen) {
                document.body.style.overflow = "scroll"
            }
        }
    }, [inscriptionOpen, loginOpen]);

    return (
    <TopBarContainer>
        <TopBarContent>
            <LogoFlexibles src="Icones/GETWELCOM NOIR.png"/>
            <UserContainer onClick={() => setDisplayProfile(!displayProfile)}>
                <ProfileIcone src="Icones/avatar-perso.c9034bb5.png"/>
                <UserName>
                    { user ? 'Hi, ' + user.firstName : null}
                </UserName>
                {displayProfile && user ?
                <Profile>
                    <DeleteButton onClick={() => {setDisplayProfile(false)}}> X </DeleteButton>
                    <Name>{ user.firstName }</Name>
                    <ButtonVa  ButtonSize={{width: "80%", height:"35px"}}  goTo="deconnect" onClick={deconnect}> Se déconnecter </ButtonVa>
                </Profile> : displayProfile ? <Profile>
                    <DeleteButton onClick={() => {setDisplayProfile(false)}}> X </DeleteButton>
                    <ButtonVa ButtonSize={{width: "40%", height:"30px"}}  goTo="login" onClick={setLoginOpen} cart={null} color="#20A7A4"> Connexion </ButtonVa>
                </Profile> : null}
            </UserContainer>
        </TopBarContent>
        { loginOpen ? <LoginForm setLoginOpen={setLoginOpen} setChange={setChange} change={change}/> : null}
    </TopBarContainer>
    );
}

const DeleteButton = styled.div`
    position: absolute;
    width: 15px;
    height: 20px;
    color: black;
    margin-left: 90%;
    :hover {
        cursor: pointer;
    }
`

const ButtonVa = styled.div`
    display: flex;

    width: 100%;
    height: 30px;
    color: #20A6A3;
    justify-content: center;
    font: normal normal normal 14px Poppins;
    :hover{
        color: white;
        background-color: #20A6A3;
        cursor: pointer;
    }

`

const Name = styled.div`
    display: flex;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    font: normal normal normal 14px Poppins;
    color: #20A6A3;
`

const Profile = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 210px;
    background-color: white;
    margin-left: 155px;
    border: 1px solid #20A6A3;
    border-radius: 10px;
    align-items: center;
`
const ProfileIcone = styled.img`
    width: 40px;
    height: auto;
    margin-left: 10px;
    border-radius: 50%;

    @media screen and (max-device-width:554px){
        height: 25px;
        width: auto; 
        margin-top: 5px;
    }
`

const UserName = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    color: black;
    @media screen and (max-device-width:480px){
        display: none;
    }
`

const UserContainer = styled.div`
    display: flex;
    width: 15%;
    flex-direction: row-reverse;

`
const TopBarContent = styled.div`
    display: flex;
    width: 95%;
    justify-content: space-between;
    @media screen and (max-device-width:480px){
        width: 90vw;
    }
`

const LogoFlexibles = styled.img`
    width: 115px;
    height: 100%;
    margin-top: auto; 
    @media screen and (max-device-width: 554px){
        width: 75px; 
    }

`

const TopBarContainer = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 11;

    justify-content: center;
    align-items: center;
    background-color: #F8F8F8;
    box-shadow: 0px 0px 1em -0.5em black;

    // Medium devices (tablets, 1024px and up)
    @media (min-width: 1024px) {

    }

    @media screen and (max-device-width:480px){
        position: relative;
        height: 41px;
        width: 100vw;
    }
    

`;

const Top = styled.div`
    background-color: #9EAEE5;
    width: 100%;
    height: 30px;
    display: ${props => props.display};
    justify-content: center;
    align-items: center;
    font-size: 12px;
`;