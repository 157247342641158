import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import UserProfile from '../UserProfile/UserProfile';
import axios from 'axios';
import {TopBar} from '../TopBar'
import history from '../history'
import Calendar from '../Calendar/Calendar'
import { Redirect } from 'react-router';
import Loader from 'react-loader-spinner';
import moment from 'moment';


export const StayInfos = ({cart, change, setChange, hostId, status}) => {
    const [isActive, setIsActive] = useState(false)
    const [valueArrival, setValueArrivals] = useState({"date": cart.beginDateOfStay, "format": null})
    const [valueDeparture, setValueDeparture] = useState({ "date": cart.endDateOfStay, "format": null})
    const [modify, setModify] = useState(false);
    const [calendarOpen, setCalendarOpen] = useState(false)
    const [user, setUser] = useState();
    let total = 0;
    const [hostData, setHost] = useState(null)
    const [scrollTop, setScrollTop] = useState(window.scrollY);
    const [cancel, setCancel] = useState(false);



    const deleteElem = (orderId, idElem, cart) => {
        let config;
        if (cart.items.length === 1) {
            config = {
                method: 'DELETE',
                url:  process.env.REACT_APP_API_URL + `/order?orderId=${orderId}`,
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                },
            }
        } else {
            config = {
                method: 'DELETE',
                url:  process.env.REACT_APP_API_URL + `/order/${orderId}/items/${idElem}`,
            }
        }
        axios(config)
            .then(function (responsePut) {
                setChange(!change)
            }).catch(function (errorPut) {
                console.log(errorPut)
            })
    }
    useEffect(() => {
        if (!user)
            setUser(JSON.parse(UserProfile.getUser()));
    }, [])
    const getDate = (date) => {
        const ordered = new Date(date).toLocaleDateString('fr', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          });
        return (ordered)
    }
    const updateOrder = () => {
        let config = {
                method: 'PATCH',
                url:  process.env.REACT_APP_API_URL + `/order`,
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                },
                data: {
                    "orderId": cart._id,
                    "beginDateOfStay": valueArrival.date,
                    "endDateOfStay": valueDeparture.date,
                    "bookingRef": cart.bookingRef,
                    "nbRoom": cart.nbRoom
                }
            }
        axios(config)
            .then(function (responsePut) {
            }).catch(function (errorPut) {
                console.log(errorPut)
            })
    }
    useEffect(() => {
        if (calendarOpen === false && valueArrival.format && valueDeparture.format) {
            updateOrder();
        }
    }, [calendarOpen])
    const getLabel = (service) => {
        if (service.selectedItem && service.selectedItem.label )
            return (service.selectedItem.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""))
        else return (service.product.label + (service.quantity > 1 ? ` (x${service.quantity})`: ""));
    }

    const getPrice = (service) => {
        if (service.selectedItem.price && service.selectedItem.price === 0)
            return "Gratuit"
        if (service.selectedItem.price) {
            total +=  parseFloat(service.selectedItem.price).toFixed(2) * service.quantity;
            return parseFloat(service.selectedItem.price).toFixed(2) * service.quantity + "€"
        }
        if (service.product.price === 0)
            return "Gratuit"
        else {
            total +=  service.product.price.toFixed(2) * service.quantity;
            return service.product.price.toFixed(2) * service.quantity + "€"
        } 
    }

    const getTotal = (cartBis) => {
        let totalPrice = 0;
            cartBis.items.map((services) => {
                if (services.selectedItems && services.selectedItems.price) {

                    return totalPrice +=  (parseFloat(services.selectedItems.price) * services.quantity);
                }
                    return totalPrice +=  (parseFloat(services.product.price) * services.quantity);
        })
        return totalPrice
    }


    const deleteOrder = async (orderId) => {
        let config = {
                method: 'DELETE',
                url:   process.env.REACT_APP_API_URL + `/order?orderId=${orderId}`,
                headers: {
                    'Authorization': 'Bearer ' + user.token,
                },
            }
        axios(config)
            .then(function (responsePut) {
                setChange(!change)
            }).catch(function (errorPut) {
                console.log(errorPut)
            })
    }

    useEffect(() => {
        if (!hostData) {
            let config = {
                method: 'GET',
                url:   process.env.REACT_APP_API_URL + `/hoster/${cart.hoster}`,
            }
            axios(config)
            .then(function (responsePut) {
                setHost(responsePut.data)
            }).catch(function (errorPut) {
                console.log(errorPut)
            })
        }
    }, [cart] )
    

    useEffect(() => {
        if (modify) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "scroll"
        }
    }, [modify])
    useEffect(() => {
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop]);
      
      const manageModify = (id, host) => {
          deleteOrder(id);
          var win = window.open(process.env.REACT_APP_MAIN_URL + `/${cart.hoster}?bookingRef=${cart.bookingRef}&arrival=${valueArrival.date}&departure=${valueDeparture.date}&room=${cart.nbRoom}`, '_self');
          win.focus();
        }

    return (
        hostData ? 
        <RecapContainer>
                <TopContainer>
                    <img style={{width: "66px", height: "66px", marginLeft: "20px"}} src={hostData.mainPhoto} />
                    <Host>
                        {hostData.name}
                        <Stay>
                            {status}
                        </Stay>
                    </Host>
                    <DateDiv>{getDate(cart.createdAt)}</DateDiv>
                </TopContainer>
                <ContainerLabel>
                     <Labels>
                        <Cat style={{marginLeft: "21.02px"}} onClick={() => {setIsActive(!isActive)}}>
                            Voir mes informations de séjour
                            <Arrow>
                                <ArrowImg src={isActive ?  "Icones/Fleche black haut.png":" Icones/Arrow - black.png"}></ArrowImg>
                            </Arrow>
                        </Cat>
                        {isActive ?
                        <StayInfo>
                            <div onClick={() => setCalendarOpen(!calendarOpen)} style={{display: "flex", alignItems: "center", marginTop: "-5px"}}>
                                <Lab>
                                    Date d'arrivée
                                </Lab>
                                <ValueLab>
                                    {getDate(valueArrival.date)}
                                </ValueLab>
                            </div>
                            <div onClick={() => setCalendarOpen(!calendarOpen)} style={{display: "flex", alignItems: "center", marginTop: "-5px"}}>
                                <Lab>
                                    Date de départ
                                </Lab>
                                <ValueLab>
                                    {getDate(valueDeparture.date)}
                                </ValueLab>
                            </div>
                            <div style={{display: "flex", alignItems: "center", marginTop: "-5px"}}>
                                <Lab>
                                    Numéro de réservation
                                </Lab>
                                <ValueLab>
                                    {cart.bookingRef}
                                </ValueLab>
                            </div>
                            <div style={{display: "flex", alignItems: "center" , marginTop: "-5px"}}>
                                <Lab>
                                    Nombre de chambre(s)
                                </Lab>
                                <ValueLab>
                                    {cart.nbRoom + " chambres(s)"}
                                </ValueLab>
                            </div>
                        </StayInfo> : null}
                        {/*calendarOpen && modify ? <Calendar  setArrival={setValueArrivals} setDeparture={setValueDeparture} setCalendarOpen={setCalendarOpen}/> : null*/}
                     </Labels>
                     <CatServ style={{marginLeft: "21.02px"}}>Prestations réservées pour ce séjour</CatServ>
                         {
                            cart && cart.status != "CANCELED" && cart.items.map((services, index) => {
                                                return (
                                                    <LabelContainer key={index}>
                                                        {   
                                                            <Label>
                                                                <LabelDiv>
                                                                    {/*modify ? <DeleteButton onClick={() => {deleteElem(cart._id, services._id, cart)}} src="Icones/IMG-0137.PNG" /> : null*/}
                                                                    <div style={{marginLeft: (modify ? "0px" : "0px")}}>{getLabel(services)} 
                                                                    
                                                                        { services.date ?
                                                                        <div style={{display: 'flex', marginLeft: '25px'}}> 
                                                                            {moment.utc(services.date).format("DD/MM/YY [ - ] HH[h]mm")} 
                                                                            {services.product.durationHour ? ` - (${services.product.durationHour} h)` : null }
                                                                            {services.product.durationMin ? ` - (${services.product.durationMin} min)` : null }
                                                                            {services.product.durationDay ? ` - (${services.product.durationDay} jour(s))` : null }
                                                                            {services.product.durationMin && services.product.Hour ? ` - (${services.product.durationHour}h${services.product.durationMinute})` : null }


                                                                        </div> : null }
                                                                    </div>
                                                                    
                                                                </LabelDiv>
                                                                <div>
                                                                    {
                                                                        getPrice(services)
                                                                    }
                                                                </div>
                                                            </Label>
                                                        }

                                                    </LabelContainer>
                                                )
                                            })
                            }
                            {modify ?
                            <CardFormModal scroll={scrollTop}>
                                <Confirm>
                                    <div style={{display: "flex", width: "90%"}}>
                                    Pour modifier cette commande, vous devez d'abord l'annuler ! Êtes-vous sûr(e) de vouloir annuler votre commande ? <br/> 
                                    Votre commande sera annulée et vous serez redirigé vers la vitrine de votre hébergement pour réaliser une nouvelle commande.<br/>

                                    Si vous souhaitez ajouter des prestations, vous pouvez simplement repasser une nouvelle commande. 
                                    </div>
                                    <ButtonDiv>
                                        <YesButton onClick={() =>  {manageModify(cart._id, hostData._id)} }> Oui </YesButton>
                                        <NoButton onClick={() => {setModify(false)}}> Non </NoButton>
                                    </ButtonDiv>
                                </Confirm>

                            </CardFormModal> : null}
                            {cancel ? 
                            <CardFormModal scroll={scrollTop}>
                                <Confirm>
                                    <div style={{display: "flex", width: "90%"}}>
                                        Êtes-vous sûr(e) de vouloir annuler cette commande ?
                                    </div>
                                    <ButtonDiv>
                                        <YesButton onClick={() =>  {deleteOrder(cart._id); setCancel(false)} }> Oui </YesButton>
                                        <NoButton onClick={() => {setCancel(false)}}> Non </NoButton>
                                    </ButtonDiv>
                                </Confirm>

                            </CardFormModal> : null}
                            {/*modify ? <AddItem  onClick={() => doReorder()}> <div style={{fontSize: "20px", marginLeft: "17px", marginRight: "10px"}}> + </div>Ajouter des options pour ce séjour</AddItem> : null*/}
                            <UnderBox>
                            <Buttons>
                                {moment.utc().isAfter(valueArrival.date) ? 
                            <ButtonToolTipContainer><Modify>
                                Modifier
                            </Modify>
                            <TooltipModify>Votre date d'arrivée est dépassée, la modification de cette commande n'est plus possible.<br/> Pour toute demande spécifique, veuillez contacter support@getwelcom.com.</TooltipModify></ButtonToolTipContainer>
                               
                               :  <Modify onClick={() => setModify(!modify)} style={{color: "#16a5a3"}}>
                                Modifier
                            </Modify>}

                            {moment.utc().isAfter(valueArrival.date) ?
                            <ButtonToolTipContainer><Cancel>
                                Annuler
                            </Cancel>
                            <TooltipCancel>Votre date d'arrivée est dépassée, l'annulation de cette commande n'est plus possible.<br/> Pour toute demande spécifique, veuillez contacter support@getwelcom.com.</TooltipCancel></ButtonToolTipContainer>
                            : <Cancel onClick={() => setCancel(true)} style={{color: "#707070"}}> Annuler </Cancel>
 }
                    </Buttons>
                    <Total>
                        <Tot>Total: </Tot>
                        <Price>{total + "€"}</Price>
                    </Total>
                </UnderBox>
            </ContainerLabel>
                
            </RecapContainer> : <Preview><Loader type="ThreeDots" color="#20A6A3" height="100" width="100" /> </Preview>
    )
}


const YesButton = styled.button`
    display: flex;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 1.26px;
    color: #FFFFFF;
    opacity: 1;
    background: #20A7A4 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: none;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
`
const NoButton = styled.button`
   display: flex;
    text-align: center;
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 1.26px;
    opacity: 1;
    background: var(--unnamed-color-20a7a4) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 3px #00000029;
    opacity: 1;
    letter-spacing: 1.26px;
    color: #20A6A3;
    opacity: 1;
    border: none;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
`
const ButtonDiv = styled.div`
    display: flex;

    justify-content: space-between;
    width: 20%;
    margin-top: 20px;
`
const Confirm = styled.div`
display: flex;
    top: 248px;
left: 760px;
height: 128px;
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 4px 4px 10px #00000029;
border-radius: 12px;
opacity: 1;
color: var(--unnamed-color-1e2224);
flex-direction: column;
text-align: center;
font: normal normal 600 14px/21px Poppins;
align-items: center;
`

const Preview = styled.div`
    display: flex;
    width: 50%;
    margin-top: 10px;
    background-color:#e0dada;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 4px 4px 10px #00000029;
    height: 300px;
    justify-content: center;
    align-items: center;
    @media screen and (max-device-width:480px){
        height: 100px;
        width: 90%;
    }

`
const CardFormModal = styled.div`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: ${props => props.scroll}px;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 30;
    background: #5C5C5C62 0% 0% no-repeat padding-box;

`

const AddItem  = styled.div`
    display: flex;

    font: italic normal 300 14px/18px Poppins;
    letter-spacing: 0px;
    color: #B5B5B5;
    margin-top: 5px;
    :hover {
        cursor: pointer;
    }
`

const Tot = styled.div`
    display: flex;
    font: normal normal bold 14px/21px Poppins;
letter-spacing: 0px;
color: #1E2224;
`
const Price = styled.div`
    display: flex;
    margin-left: 5px;
    font: normal normal bold 14px/21px Poppins;
letter-spacing: 0px;
color: #1E2224;
`
const Total = styled.div`
    display: flex;
    font: normal normal medium 14px/21px Poppins;
letter-spacing: 0px;
color: #1E2224;
`
const Modify = styled.div`
    display: flex;
    font: normal normal 300 12px/18px Poppins;
letter-spacing: 0px;
color: #B5B5B5;
:hover {
    cursor: pointer;
    
}
`

const ButtonToolTipContainer = styled.div`
:hover label{
    visibility: visible;
}
`

const TooltipModify = styled.label`
    font: normal normal 300 11px Poppins;
    background-color: #16a5a3;
    color: white; 
    border-radius: 5px; 
    padding: 5px; 
    position: absolute;
    visibility: hidden; 
    // display: none; 
`

const TooltipCancel = styled.label`
    font: normal normal 300 11px Poppins;
    background-color: #16a5a3;
    color: white; 
    border-radius: 5px; 
    padding: 5px; 
    position: absolute;
    visibility: hidden; 
    // display: none; 
`


const Cancel = styled.div`
    display: flex;
    font: normal normal 300 12px/18px Poppins;
letter-spacing: 0px;
color: #B5B5B5;
margin-left: 13px;
:hover {
    cursor: pointer;
}
`
const Buttons = styled.div`
    display: flex;
    margin-left: 21px;
`
const UnderBox = styled.div`
    display: flex;
    width: 99%;
    justify-content: space-between;
    margin-top: 19.65px;
    margin-bottom: 20px;
    align-items: center;
`


const DeleteButton = styled.img`
    display: flex;
    width: 10px;
    height: 12px;
    :hover {
        cursor: pointer;
    }
`


const Lab = styled.div`
    display: flex;
    font: normal normal normal 14px Poppins;
letter-spacing: 0px;
color: #B5B5B5;
`

const ValueLab = styled.div`
    display: flex;
    margin-left: 7px;
    font: normal normal 300 14px/30px Poppins;
letter-spacing: 0px;
color: #B5B5B5;
`

const StayInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 21.02px;
    margin-top: 5px;
`
const ArrowImg = styled.img`
    width: 6.63px;
    height: 3.68px;
`
const Arrow = styled.button`
    display: flex;
    width: 10px;
height: 5px;
    border: none;
    justify-content: center;
    margin-left: 5px;
    background-color: transparent;
    :hover {
        background-color: lightgray;
    }
    @media screen and (max-device-width:1334px){
        margin-left: 25%;
    }
    @media screen and (max-device-width:480px){
        margin-left: 48vw;
    }
`

const CatServ = styled.div`
    display: flex;
    font: normal normal 600 14px/21px Poppins;
    align-items: center;
`

const Cat = styled.div`
    display: flex;
    font: normal normal 600 14px/21px Poppins;
    align-items: center;
    :hover {
        cursor: pointer;
    }
`
const DateDiv = styled.div`
     display: flex;
     font: normal normal 300 12px/18px Poppins;
letter-spacing: 0px;
color: #1E2224;
`
const Stay = styled.div`
    display: flex;
    color: #20A7A4;
    font: italic normal 300 14px/21px Poppins;

`
const Host = styled.div`
    display: flex;
    width: 82%;
    flex-direction: column;
    margin-left: 12px;
`
const Logo = styled.img`
    display: flex;
    width: 12px;
height: 7px;
transform: matrix(0, 1, -1, 0, 0, 0);
opacity: 1;
`

const GoBackContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24.85px;
    width: 50%;
    @media screen and (max-device-width:480px){
        margin-top: 0px;
    }
    :hover {
        cursor: pointer;
    }
`

const LabelCat = styled.div`
    display: flex;
    width: 100%;
    font: normal normal medium 14px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
`
const Label = styled.div`
    display: flex;
    width: 100%;
    color: black;
    font: normal normal 300 14px/30px Poppins;
letter-spacing: 0px;
color: #1E2224;

    justify-content: space-between;
`
const LabelDiv = styled.div`
    display: flex;
    margin-left: -8px;
    align-items: center;
`
const Dot = styled.div`

    margin-left: 15px;
    margin-right: 15px;

`
const Slot = styled.div`
   
`
const Labels = styled.div `
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    font: normal normal 600 17px/26px Poppins;
    flex-direction: column;
`

const ContainerLabel = styled.div`
    display: flex;
    width: 95%;
    justify-content: center;
    margin-top: 14.63px;
    flex-direction: column;
`

const LabelContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 5px;
    @media screen and (max-device-width:480px){
        width: 80%;
    }
`
const ThanksContent = styled.div `
    display: flex;
    margin-left: 19px;
    margin-top: 11px;
    font: normal normal 300 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
    flex-direction: column;
`
const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #F7F7F7;
    flex-direction: column;
    align-items: center;
    margin-top: 103px;
`

const ThanksContainer = styled.div`
    display: flex;
    width: 50%;
    height: 218px;
    margin-top: 10px;
    background-color: #B3DFE0;
    border-radius: 12px;
    flex-direction: column;
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
        margin-top: 10px;
    }
`
const TopContainer = styled.div`
    display: flex;
    color: #1E2224;
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 1.53px;
    padding-left: 19px;
    margin-top: 16px;
`
const RecapContainer = styled.div`
    display: flex;
    width: 50%;
    margin-top: 10px;
    background-color:#FFFFFF;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 4px 4px 10px #00000029;
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
    }
`