import styled from 'styled-components';
import { TopBar } from './TopBar';
import { Stays } from './Stays';
import React from "react";
import {
  Switch
} from "react-router-dom";


import { Router, Route } from "react-router";
import history from './history';

function Page() {
  return (
    <MainContainer>
    {<Switch>
    <Route path="/" component={Stays}/>
    {/*<Route path="/:token" component={Stays}/>*/}
    </Switch>}
  </MainContainer>
  );
}

const App = () => {
  return (
      <Container>
        <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'/>
            <Router history={history}>
              <Page />
            </Router>
        <BottomContainer>
          <BottomContainerFlexBox>
            <UnderBox>
              <Title>
                  GetWelcome
              </Title>
              <div style={{display: "flex", flexDirection: "column", height: "90%", justifyContent: "space-between"}}>
                <SubTitle style={{marginTop: "20px"}}>Découvrir GetWelcome</SubTitle>
                <SubTitle>Nous contacter</SubTitle>
                <SubTitle>FAQ</SubTitle>
              </div>
            </UnderBox>
            <UnderBox>
              <Title>
                  Légal
              </Title>
              <div style={{display: "flex", flexDirection: "column", height: "90%", justifyContent: "space-between"}}>
              <SubTitle  style={{marginTop: "20px"}}>CGU</SubTitle>
              <SubTitle>CGV</SubTitle>
              <SubTitle>Politique de confidentialité</SubTitle>
              </div>
            </UnderBox>
            <UnderBox>
              <Title>
                  Réseaux sociaux
              </Title>
              <div style={{display: "flex", flexDirection: "column", height: "90%", justifyContent: "space-between"}}>
              <SubTitle  style={{marginTop: "20px"}}>Instagram</SubTitle>
              <SubTitle>Facebook</SubTitle>
              <SubTitle>Linkedin</SubTitle>
              </div>
            </UnderBox>
            </BottomContainerFlexBox>
        </BottomContainer>
      </Container>
  );
}

const BottomContainerFlexBox = styled.div`
  display: flex;
   width: 60%;
   height: 100%;
   flex-direction: row;
    justify-content: space-between;
    align-items: center;
  @media screen and (max-device-width:1024px){
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }
`
const SubTitle = styled.div`
  color: var(--unnamed-color-1e2224);
text-align: left;
font: normal normal medium 17px/26px Poppins;
letter-spacing: 1.53px;
color: #1E2224;
opacity: 1;
`
const Title = styled.div`
  color: var(--unnamed-color-1e2224);
  text-align: left;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 1.8px;
  color: #1E2224;
  @media screen and (max-device-width:1024px){
        font: normal normal bold 14px Poppins;
    }
  
`
const UnderBox = styled.div`
  display: flex;
  height: 40%;
  flex-direction: column;
  margin-top: 10px;
`
const BottomContainer = styled.div`
  display: flex;
  background-color: #EDEDED;
  width: 100%;
  min-height: 30vh ;
  justify-content: center;
  align-items: center;
  @media screen and (max-device-width:1024px){
        justify-content: flex-start;
        margin-top: 40px;
        height: 400px;
    }
    @media screen and (max-device-width:480px){
        justify-content: flex-start;
        margin-top: 40px;
        min-height: 55vh ;
        height: 100%;
    }

`
const MainContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;

  @media screen and (max-device-width:480px){
        width: 100vw;
    }
`

const Container = styled.header`
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  background-color: #F7F7F7;
  @media screen and (max-device-width:1024px){
    height: auto;
  }
  @media screen and (max-device-width:480px){
      width: 100%;
      flex: 1;
      flex-flow: auto;
      height: auto;
  }



`


export default App;
