import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import UserProfile from '../UserProfile/UserProfile';
import axios from 'axios';
import {TopBar} from '../TopBar'
import { StayInfos } from '../StayInfos'
import {LoginForm} from '../LoginForm'
import history from '../history'
import jwt_decode from 'jwt-decode';
import { LoadingIndicator } from '../Loader';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import Loader from 'react-loader-spinner';


export const Stays = (props) => {
    const cartId = UserProfile.getUserCartId();
    const [carts, setCarts] = useState();
    const [loading, setLoading] = useState(true);
    const [isActive, setIsActive] = useState(false)
    const [isLoginOpen, setIsLoginOpen] = useState(true);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [filter, setFilter] = useState("");
    const [modify, setModify] = useState(false);
    const [change, setChange] = useState(false)
    const { promiseInProgress } = usePromiseTracker();
    const [token, setToken] = useState(null);
    
    const goBack = () => {
        history.push("/");
        return
    }
    const [user, setUser] = useState();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get("token")) {
            setToken(urlParams.get("token"))
        }
    }, [])

    const sendGetRequest = async () => {
        if (user && user.token) {
            try {
                const resp = await axios.get(process.env.REACT_APP_API_URL + '/customerorder', {headers: {
                'Authorization': 'Bearer ' + user.token},
                }).then(resp => {
                    setLoading(false)
                    setCarts(resp.data.data);
                    setIsLoginOpen(false);
                })
            } catch (err) {
                console.error(err);
            }
        }
    };
    
    useEffect(() => {
        if (token) {
            let profile =  jwt_decode(token);
            UserProfile.setUser(JSON.stringify({
                'firstName': profile.given_name,
                'mail': profile.email,
                'token' : token,
            }))
            setUser({
                'firstName': profile.given_name,
                'mail': profile.email,
                token,
            });
            setChange(!change)
        } else {
            setUser(JSON.parse(UserProfile.getUser()));
            setChange(!change)
        }
    }, [isLoginOpen, token])
    
    useEffect(() => {
        if (user && user.token) {
            sendGetRequest();
        } 
    }, [user, change]);

    useEffect(() => {
        if (!user) {
            document.body.style.overflow = "hidden"
            sendGetRequest();
        }
        if (user) {
            document.body.style.overflow = "scroll"
        }  
    }, [user, change]);



    const compareDate = (stayDateBegin, stayDateEnd) => {
        const date = new Date();
        const begin = new Date(stayDateBegin);
        const end = new Date(stayDateEnd);
        if (date.getTime() < begin.getTime())
            return ("Séjours à venir")
        else if (date.getTime() >= begin.getTime() && date.getTime() <= end.getTime())
            return ("Séjours en cours")
        else return ("Séjours passés")
    }
    const getDate = (date) => {
        const ordered = new Date(date).toLocaleDateString('fr', {
            day: '2-digit',
            month: '2-digit',
            year: "2-digit"
        });
        return (ordered)
    }
   
    const checkFilter = (status) => {
        if (status === filter || filter === "")
            return true
        else return false;
    }


    return (
        <div style={{display: "flex", width: "100%", flexDirection: "column", minHeight: "90vh", height: "100%"}}>
        <TopBar user={user} setChange={setChange} change={change}/>
        {!user ? <LoginForm setLoginOpen={setIsLoginOpen} setChange={setChange} change={change}/> :
        !loading ? <Container>
            <GoBackContainer>
                <div>Mes séjours</div>  
                <div style={{display: "flex", alignItems: "center", width: "85px", position:"relative"}} onClick={() => {setIsFilterActive(!isFilterActive)}}>
                    Trier par
                    <Arrow>
                        <ArrowImg src={isFilterActive ?  "Icones/Fleche black haut.png":" Icones/Arrow - black.png"}></ArrowImg>
                    </Arrow>
                    {isFilterActive ? 
                    <FilterPicker>
                        <Filter onClick={() => {setFilter("Séjours à venir"); setIsFilterActive(false)}}>
                            Séjours à venir
                        </Filter>
                        <Filter onClick={() => {setFilter("Séjours en cours"); setIsFilterActive(false)}}>
                            Séjours en cours
                        </Filter>
                        <Filter onClick={() => {setFilter("Séjours passés"); setIsFilterActive(false)}}>
                            Séjours passés
                        </Filter>
                    </FilterPicker>: null}
                </div>        
            </GoBackContainer>
            {
            !loading && carts && carts.map((cart, index) => {

            const status  = compareDate(cart.beginDateOfStay);
            if (!checkFilter(status) || cart.status === "CANCELED")
                return null;
            return (
                <StayInfos key={index} cart={cart} modify={modify} setModify={setModify} user={user} change={change} setChange={setChange} status={status} hostId={"60a3bb1b0fa07c0007c51c88"} />
            )
            })
            }
        </Container> : null}
        </div>
    )
}

const InfoStay = styled.div`
    display: flex;
    width: 50%;
    height: 56px;
background: #B3DFE0 0% 0% no-repeat padding-box;
border-radius: 12px;
opacity: 1;
margin-top: 23.26px;
font: normal normal 600 16px/25px Poppins;
letter-spacing: 0px;
color: #1D1D1D;
opacity: 1;
justify-content: center;
align-items: center;
@media screen and (max-device-width:480px){
    font: normal normal 600 12px/18px Poppins;
    margin-top: 15px;
    width: 90%;
    margin-top: -50px;
    }
    padding-left: 10px;

`
const Filter = styled.div`
    display: flex;
    margin-top: 10px;
    :hover {
        cursor: pointer;
    }
`
const FilterPicker = styled.div`
    display: flex;
    position: absolute;
    width: 175px;
    background-color: white;
    margin-left: 80px;
    z-index: 9;
    margin-top: 70px;
    border: 1px solid #20A6A3;
    border-radius: 12px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media screen and (max-device-width:480px){
        top: 20px;
        margin-top: 0px;
        left: -170px
    }
`

const ArrowImg = styled.img`
    width: 6.63px;
    height: 3.68px;
`
const Arrow = styled.button`
    display: flex;
    width: 10px;
height: 5px;
    border: none;
    justify-content: center;
    margin-left: 5px;
    background-color: transparent;
    :hover {
        background-color: lightgray;
    }

`

const CatServ = styled.div`
    display: flex;
    font: normal normal 600 14px/21px Poppins;
    align-items: center;
    margin-bottom: 11.29px;
`

const Cat = styled.div`
    display: flex;
    font: normal normal 600 14px/21px Poppins;
    align-items: center;
    :hover {
        cursor: pointer;
    }
`
const DateDiv = styled.div`
     display: flex;
     font: normal normal 300 12px/18px Poppins;
letter-spacing: 0px;
color: #1E2224;
margin-right: 15px;
`
const Stay = styled.div`
    display: flex;
    color: #20A7A4;
    font: italic normal 300 14px/21px Poppins;

`
const Host = styled.div`
    display: flex;
    width: 82%;
    flex-direction: column;
    margin-left: 12px;
`
const Logo = styled.img`
    display: flex;
    width: 12px;
height: 7px;
transform: matrix(0, 1, -1, 0, 0, 0);
opacity: 1;
`

const GoBackContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24.85px;
    width: 50%;
    justify-content: space-between;
    @media screen and (max-device-width:480px){
        margin-top: 0px;
        width: 90%;
    }
    :hover {
        cursor: pointer;
    }
`

const LabelCat = styled.div`
    display: flex;
    width: 100%;
    font: normal normal medium 14px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
`
const Label = styled.div`
    display: flex;
    width: 100%;
    color: black;
    font: normal normal 300 14px/30px Poppins;
letter-spacing: 0px;
color: #1E2224;

    justify-content: space-between;
`
const LabelDiv = styled.div`
    display: flex;
    margin-left: -8px;
    align-items: center;
`

const Labels = styled.div `
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    font: normal normal 600 17px/26px Poppins;
    flex-direction: column;
`

const ContainerLabel = styled.div`
    display: flex;
    width: 95%;
    justify-content: center;
    margin-top: 14.63px;
`

const LabelContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 5px;
    @media screen and (max-device-width:480px){
        width: 80%;
    }
`
const ThanksContent = styled.div `
    display: flex;
    margin-left: 19px;
    margin-top: 11px;
    font: normal normal 300 14px/21px Poppins;
    letter-spacing: 0px;
    color: #1E2224;
    flex-direction: column;
`
const Container = styled.div`
    display: flex;
    width: 100%;
    background-color: #F7F7F7;
    flex-direction: column;
    align-items: center;
    margin-top: 103px;
    min-height: 90%;
    height: auto;
`

const ThanksContainer = styled.div`
    display: flex;
    width: 50%;
    height: 218px;
    margin-top: 10px;
    background-color: #B3DFE0;
    border-radius: 12px;
    flex-direction: column;
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
        margin-top: 10px;
    }
`
const TopContainer = styled.div`
    display: flex;
    width: 100%;
    color: #1E2224;
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 1.53px;
    margin-top: 16px;
`
const RecapContainer = styled.div`
    display: flex;
    width: 50%;
    margin-top: 10px;
    background-color:#FFFFFF;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 4px 4px 10px #00000029;
    @media screen and (max-device-width:480px){
        height: auto;
        width: 90%;
    }
`