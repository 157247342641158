import React, {useEffect, useState }from "react"
import styled from "styled-components"
import { ValidationButton } from "../ValidationButton";
import axios from "axios";
import UserProfile from "../UserProfile/UserProfile";
import QueryString from 'qs';
import jwt_decode from 'jwt-decode';


export const LoginForm = ({setLoginOpen, setChange, change}) => {
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [id, setId] = useState();
    const [firstName, setFirstName] = useState("")
    const [profile, setProfile] = useState();
    const [refreshToken, setRefreshToken] = useState();
    const [token, setToken] = useState();
    

    useEffect(() => {
        if (id && firstName) {
            const profile = JSON.stringify({"firstname": firstName, "id": id})
            UserProfile.setUser(profile);
            setLoginOpen(false);
        }
    }, [id, firstName])

    const validateRegister = () => {
        const dataSend = QueryString.stringify({
            'grant_type': 'password',
            'username': mail,
            'password': password,
            'client_id' : process.env.REACT_APP_CLIENTID
        })
        var config = {
            method: 'POST',
            url: process.env.REACT_APP_AUTH_URL,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: dataSend
        };
        
        axios(config)
        .then(async function (response) {
            setProfile((jwt_decode(response.data.access_token)));
            setToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token)
            setChange(!change)
        })
        .catch(function (error) {
            console.log(error);
            return false;
        });

    }

    useEffect(() => {
        if (profile && token && refreshToken) {
            UserProfile.setUser(JSON.stringify({
                'firstName': profile.given_name,
                'mail': profile.email,
                token,
                refreshToken,
                
            }))
            setLoginOpen(false);
        }
    }, [profile, token, refreshToken])
    const handleRegister = () => {
        if (!mail || !password)
            return;
        validateRegister();
    }
    return (
        <CardFormModal>
            <CardFormContainer>
                <Title>
                    Connexion
                </Title>
                <CardForm>
                    <LabelForm for="mail"> Adresse mail</LabelForm>
                    <InputForm type="mail" name="mail" id="mail" onChange={(e) => setMail(e.target.value)} border={mail}/>

                    <LabelForm for="pwd"> Mot de passe</LabelForm>
                    <InputForm type="password" name="pwd" id="pwd" onChange={(e) => setPassword(e.target.value)} border={password}/>

                </CardForm>
                <div style={{display: "flex", marginTop: "50px", width: "100%", justifyContent: "center"}}>
                    <ValidationButton Title="Se connecter" ButtonSize={{width: "50%", height:"35px"}}  goTo="regi" onClick={handleRegister}/>
                </div>
            </CardFormContainer>
        </CardFormModal>
    )
}

const Title = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    font: normal normal 600 17px/26px Poppins;
    letter-spacing: 1.53px;
    color: #FFFFFF;
    margin-top: 20px;
`

const LabelForm = styled.label`
    display: flex;
    margin-top: 20px;
    font: normal normal medium 14px/30px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
`
const InputForm = styled.input`
    display: flex;
    background-color: transparent;
    border: none;
    border-bottom: ${props => props.border ? "none" : "1px solid white"};
    outline: none;
    color: white;
    text-align: left;
    font: italic normal 300 14px/30px Poppins;
    height: 20px;

`;
const CardForm = styled.form`
    width: 90%;
    margin-left: 20px;
    margin-top: 30px;
`
const CardFormContainer = styled.div`
    display: flex;
    width: 439px;
    height: 699px;
    flex-direction: column;
    box-shadow: 4px 4px 10px #00000029;
    border-radius: 18px;
    background-color: #20A7A4;
    align-items: flex-start;
    align-items: center;
`

const CardFormModal = styled.div`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background: #5C5C5C62 0% 0% no-repeat padding-box;

`