let UserProfile = (function() {
    let full_name = "";
    let cartUser = [];
    let getName = function() {
      return full_name;    // Or pull this from cookie/localStorage
    };
  
    let setName = function(name) {
      full_name = name;     
      // Also set this in cookie/localStorage
    };
    let setCartId = function(cartId) {
      localStorage.setItem("cartId", cartId);
    }
    let getCartId = function() {
      return localStorage.getItem("cartId");
    }
    let setCart = function(cart) {
      localStorage.setItem("cart_test", cart);
    }
    let getCart = function() {
      return localStorage.getItem("cart_test");
    }
    let setStayInfoNumber = function(stayInfoNumber) {
      localStorage.setItem("stay_info_number", stayInfoNumber);
    }
    let getStayInfoNumber = function() {
      return localStorage.getItem("stay_info_number");
    }
    let setStayInfoRoom = function(stayInfoNumber) {
      localStorage.setItem("stay_info_room", stayInfoNumber);
    }
    let getStayInfoRoom = function() {
      return localStorage.getItem("stay_info_room");
    }
  
    let setUser = function(data) {
      localStorage.setItem("user", data);
    }
    let getUser = function() {
      return localStorage.getItem("user");
    }
    let setUserCartId = function (id) {
      localStorage.setItem("cartId", id)
    }
    let getUserCartId = function () {
      return localStorage.getItem("cartId")
    }
    


    return {
      getName: getName,
      setName: setName,
      setCartId,
      getCartId,
      setCart,
      getCart,
      setStayInfoNumber,
      getStayInfoNumber,
      setStayInfoRoom,
      getStayInfoRoom,
      setUser,
      getUser,
      setUserCartId,
      getUserCartId
    }
  
  })();

  export default UserProfile;